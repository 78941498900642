import { useState } from 'react';

import { shallowEqual } from '@/utils/shallowEqual';

// eslint-disable-next-line no-restricted-imports
import { useBranchChangeSubscription } from '@/domains/branches/api/useBranchChangeSubscription';
import {
  useUserInfoSubscription,
  useSiteStatusSubscription,
  useCurrentBranchInfoSubscription,
} from '@knapsack/hasura-gql-client/apollo';
import { canInstanceStatusBeAccessed } from '@knapsack/core';
import {
  getSessionInstanceStatus,
  setSessionInstanceStatus,
} from '@/domains/branches/utils';
import { runInstanceStatusIntercept } from '@/domains/branches';
import { isCypress } from '@/utils/constants';
import type { KscUser } from '@/types';
import {
  useAppCtxSelector,
  useAppStateMatches,
  sendAppEvent,
  sendAppClientDataEvent,
  useInstanceId,
  useAppClientDataCtxSelector,
} from '../xstate';

export function useAppGqlSubscriptions() {
  useBranchChangeSubscription();

  const [lastRoleId, setLastRoleId] = useState('');
  const userId = useAppCtxSelector(({ user }) => user?.userId);
  const userInfo = useAppCtxSelector(({ user }) => user?.info ?? {});
  const siteId = useAppCtxSelector(({ site }) => site?.meta.siteId);
  /** This is only present when we are cloud authoring and on a branch */
  const instanceId = useInstanceId();
  const isUserFullyLoaded = useAppStateMatches('user.loggedIn.loaded.userInfo');
  const currentLastCommittedDataChangeId = useAppClientDataCtxSelector(
    (ctx) => ctx.lastCommittedDataChangeId,
  );
  const currentLastDataChangeId = useAppClientDataCtxSelector(
    (ctx) => ctx.lastDataChangeId,
  );
  const currentInstanceStatus = useAppCtxSelector(({ site }) =>
    site?.contentSrc.type === 'cloud-authoring' &&
    site.contentSrc.instance.type === 'branch'
      ? site.contentSrc.instance.instanceStatus
      : null,
  );

  // Set the initial session instance status
  const initialSessionInstanceStatus = getSessionInstanceStatus({ instanceId });
  if (!initialSessionInstanceStatus && currentInstanceStatus) {
    setSessionInstanceStatus({
      instanceId,
      instanceStatus: currentInstanceStatus,
    });
  }

  useCurrentBranchInfoSubscription({
    variables: { instanceId },
    skip: !instanceId,
    onData({ data: { data } }) {
      if (!data?.siteInstance) return;
      const {
        lastCommittedDataChangeId,
        /**
         * A single object array that contains the most recent data change by date
         */
        lastOneDataChanges = [],
        statusId: instanceStatus,
      } = data.siteInstance;
      const lastDataChangeId = lastOneDataChanges[0]?.id;
      const canBeAccessed = canInstanceStatusBeAccessed({ instanceStatus });
      if (!canBeAccessed || instanceStatus !== currentInstanceStatus) {
        runInstanceStatusIntercept({ instanceId, instanceStatus });
      }
      if (
        lastCommittedDataChangeId &&
        lastCommittedDataChangeId !== currentLastCommittedDataChangeId
      ) {
        sendAppClientDataEvent({
          type: 'appClientData.setLastCommittedDataChangeId',
          id: data.siteInstance.lastCommittedDataChangeId,
        });
      }
      if (lastDataChangeId && lastDataChangeId !== currentLastDataChangeId) {
        sendAppClientDataEvent({
          type: 'appClientData.setLastDataChangeId',
          id: lastDataChangeId,
        });
      }
      if (instanceStatus && instanceStatus !== currentInstanceStatus) {
        sendAppEvent({
          type: 'site.instanceStatusChanged',
          instanceStatus,
        });
      }
    },
  });

  useUserInfoSubscription({
    variables: {
      userId,
    },
    skip: !isUserFullyLoaded && !isCypress(),
    onData({ data: { data } }) {
      if (!data?.user) return;
      const { displayName, profilePic, sites, responsibilityId, dateCreated } =
        data.user;
      const newInfo: KscUser = {
        ...userInfo,
        displayName,
        profilePic,
        responsibilityId,
        dateCreated,
      };

      if (!shallowEqual(userInfo, newInfo)) {
        sendAppEvent({
          type: 'user.infoChanged',
          info: newInfo,
        });
      }

      // roleId of current site ONLY
      const roleId = sites?.find(
        ({ siteId: siteId_ }) => siteId_ === siteId,
      )?.roleId;

      if (!roleId) {
        return;
      }

      // Cache roleId lookup
      if (lastRoleId === '' && roleId) {
        setLastRoleId(roleId);
        return;
      }

      // Now that there is a sites state, compare against incoming sites
      if (lastRoleId !== roleId) {
        setLastRoleId(roleId);

        // Warn then logout the user so claims are reregistered on next login
        sendAppEvent({
          type: 'app.sendUserMsg',
          msg: {
            type: 'warning',
            message: 'Your role has changed. Signing you out.',
            autoClose: 10000,
            onClose: () => sendAppEvent({ type: 'user.signOut' }),
          },
        });
      }
    },
  });

  const siteStatus = useAppCtxSelector(({ site }) => site?.meta.status);

  useSiteStatusSubscription({
    variables: {
      siteId,
    },
    skip: !siteId,
    onData({ data: { data } }) {
      if (!data) return;
      if (siteStatus === data.site.status) return;
      sendAppEvent({ type: 'site.statusChanged', status: data.site.status });
    },
  });
}
