import {
  sendAppEvent,
  sendUiEvent,
  useInstanceId,
  useInstanceStatus,
} from '@/core';
import { ModalProps } from '@/core/layout/modal';
import { SiteInstanceStatuses_Enum } from '@knapsack/hasura-gql-client';
import {
  ButtonGroup,
  ButtonPrimary,
  ButtonSecondary,
  componentSpacing,
} from '@knapsack/toby';
import {
  getSessionInstanceStatus,
  setSessionInstanceStatus,
} from '../../utils';

const DismissButton = () => {
  const instanceId = useInstanceId();
  const instanceStatus = useInstanceStatus();
  return (
    <ButtonSecondary
      fullWidth
      label="Dismiss"
      onTrigger={() => {
        sendUiEvent('modal.triggerClose');
        setSessionInstanceStatus({
          instanceId,
          instanceStatus,
        });
      }}
    />
  );
};

const GoToLatestButton = () => {
  const instanceId = useInstanceId();
  const instanceStatus = useInstanceStatus();
  return (
    <ButtonPrimary
      fullWidth
      label="Go to Latest"
      onTrigger={() => {
        sendUiEvent('modal.triggerClose');
        setSessionInstanceStatus({
          instanceId,
          instanceStatus,
        });
        sendAppEvent({
          type: 'site.switchInstance',
          instance: { type: 'latest' },
        });
      }}
    />
  );
};

function getBranchStatusContent({
  instanceStatus,
}: {
  instanceStatus: SiteInstanceStatuses_Enum;
}): ModalProps | null {
  if (!instanceStatus) return null;
  switch (instanceStatus) {
    case 'CLEAN_DRAFT':
    case 'DRAFT':
      return {
        testId: 'branchStatusIntercept--DRAFT',
        title: 'Branch Status Updated to Draft',
        subtitle: `This branch was in review but has been moved back to a draft. You can now make changes as needed before submitting it for review again.`,
        body: <DismissButton />,
      };
    case 'DELETED':
      return {
        testId: 'branchStatusIntercept--DELETED',
        title: 'Oops! This Branch Has Been Deleted',
        subtitle: `It looks like the branch you're trying to access has been deleted, so it’s no longer available for editing. Need to make more changes? No problem! Just create a new branch from the latest version.`,
        body: <GoToLatestButton />,
        bodyClassName: componentSpacing({ marginBlock: 'medium' }),
        preventClose: true,
        preventEscKeyClose: true,
      };
    case 'PROPOSING':
    case 'PROPOSED':
      return {
        testId: 'branchStatusIntercept--PROPOSED',
        title: 'This Branch Has Been Marked as Ready to Review',
        subtitle: `It looks like the branch you're trying to access has been marked as ready to review, so it’s no longer available for editing. Need to make more changes? No problem! Just dismiss this message and click Edit to make more changes.`,
        body: (
          <ButtonGroup>
            <DismissButton />
            <GoToLatestButton />
          </ButtonGroup>
        ),
        bodyClassName: componentSpacing({ marginBlock: 'medium' }),
      };
    case 'PUBLISHING':
    case 'PUBLISHED':
      return {
        testId: 'branchStatusIntercept--PUBLISHED',
        title: 'Oops! This Branch Has Already Been Published',
        subtitle: `It looks like the branch you're trying to access has already been published, so it’s no longer available for editing. Need to make more changes? No problem! Just create a new branch from the latest version.`,
        body: <GoToLatestButton />,
        bodyClassName: componentSpacing({ marginBlock: 'medium' }),
        preventClose: true,
        preventEscKeyClose: true,
      };
    default: {
      const _exhaustiveCheck: never = instanceStatus;
      throw new Error(`Unhandled instance status: ${_exhaustiveCheck}`);
    }
  }
}

export async function runInstanceStatusIntercept({
  instanceId,
  instanceStatus,
}: {
  instanceId: string;
  instanceStatus: SiteInstanceStatuses_Enum;
}) {
  // @todo when user initiates the change, they should not see this intercept modal
  return;
  const branchStatusContent = getBranchStatusContent({ instanceStatus });
  const sessionInstanceStatus = getSessionInstanceStatus({ instanceId });
  /**
   * If the instanceStatus is DELETED, wait until it's no longer DELETED or timeout after 4 seconds
   * We sometimes transition through the DELETED state, so we need to ensure that the instance is really deleted before showing the modal
   * */
  let timeElapsed = 0;
  while (instanceStatus === 'DELETED' && timeElapsed < 4000) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    timeElapsed += 1000;
  }
  if (instanceStatus !== sessionInstanceStatus) {
    sendUiEvent([
      {
        type: 'modal.setContent',
        modal: {
          ...branchStatusContent,
          size: 'wide',
        },
      },
      'modal.triggerOpen',
    ]);
  } else {
    sendUiEvent('modal.triggerClose');
  }
}
