import { useMemo } from 'react';
import { KnapsackNavItem, KnapsackNavItemId, PageType } from '@knapsack/types';
import { navByIdToArray } from '@/domains/navs/utils/nav-data';
import { BASE_PATHS } from '@/utils/constants';
import { determineTopNavItem, getNavItemByPathName } from '@/utils/nav';
import { usePathname } from 'next/navigation';
import {
  useSelector,
  useAppCanEdit,
  useUserRole,
  useIsSitePrivate,
} from '../../core';

/**
 * Turn the navsState.byId object into an array for easy use within UI
 */
export function useNavItems({
  includeHiddenItems = false,
}: {
  includeHiddenItems?: boolean;
}): KnapsackNavItem[] {
  const canUserEdit = useAppCanEdit();
  // Nav state, byId is object, order is sorting array
  const { byId, order } = useSelector((s) => s.navsState, {
    isEqualityDeep: true,
  });

  return useMemo(() => {
    const allItems = navByIdToArray(byId, order).map((navItem) => {
      if (!navItem?.path) return navItem;
      return {
        ...navItem,
        path: navItem?.path,
        name: navItem?.name || navItem?.path,
      };
    });

    if (!includeHiddenItems && !canUserEdit) {
      return allItems.filter((item) => !item?.hidden);
    }

    return allItems;
  }, [byId, order, includeHiddenItems, canUserEdit]);
}

export function useActiveTopNavItem() {
  const navItems = useNavItems({ includeHiddenItems: true });
  const pathname = usePathname();
  return useMemo(() => {
    const activeSubNavId = getNavItemByPathName({
      source: navItems,
      pathname,
    });
    return determineTopNavItem({
      source: navItems,
      id: activeSubNavId?.id,
    });
  }, [navItems, pathname]);
}

function getFallbackNavIds({
  id,
  type,
}: {
  id: KnapsackNavItemId;
  type: 'page' | 'pattern' | 'group';
}) {
  return [`/${type}/${id}`, `/${type}s/${id}`];
}

export function useNavItem({
  id,
  type,
}: {
  id: KnapsackNavItemId;
  type?: 'page' | 'pattern' | 'group';
}): KnapsackNavItem | undefined {
  const navsById = useSelector((s) => s.navsState.byId, {
    isEqualityDeep: true,
  });
  let navId = id;
  // control failure
  if (!navsById[id] && type) {
    const possibleIds = getFallbackNavIds({ id, type });
    const newId = possibleIds.find((pId) => navsById[pId] !== undefined);
    if (newId) navId = newId;
  }
  return useMemo(() => navsById[navId], [navId, navsById]);
}

/**
 * Pull the keys off navs.byId object
 */
export const useNavIds = () => {
  return useSelector((s) => Object.keys(s.navsState.byId), {
    isEqualityDeep: true,
  });
};

/**
 * @returns Array of nav ids marked hidden
 */
export const useHiddenNavIds = ({
  navItemType,
}: {
  navItemType?: 'groups' | 'pages' | 'patterns';
}) => {
  const navsById = useSelector((s) => s.navsState.byId, {
    isEqualityDeep: true,
  });

  return useMemo(() => {
    const hiddenNavIds = Object.keys(navsById).filter(
      (id) => navsById[id].hidden,
    );

    if (navItemType) {
      switch (navItemType) {
        case 'groups':
          return hiddenNavIds?.filter((id) => !navsById[id]?.path);
        case 'pages':
          return hiddenNavIds?.filter((id) =>
            navsById[id]?.path?.startsWith(BASE_PATHS.PAGES),
          );
        case 'patterns':
          return hiddenNavIds?.filter((id) =>
            navsById[id]?.path?.startsWith(BASE_PATHS.PATTERN),
          );
        default: {
          const _exhaustiveCheck: never = navItemType;
          throw new Error(`Unhandled navItemType: ${_exhaustiveCheck}`);
        }
      }
    }

    return hiddenNavIds;
  }, [navsById, navItemType]);
};

/**
 * @returns Array of nav ids marked private based on user role
 */
export const usePrivateNavIds = () => {
  const isSitePrivate = useIsSitePrivate();
  const userRole = useUserRole();
  const navsById = useSelector((s) => s.navsState.byId, {
    isEqualityDeep: true,
  });

  return useMemo(() => {
    const privateNavIds = Object.keys(navsById).filter((id) => {
      switch (userRole) {
        case 'ANONYMOUS':
          if (isSitePrivate) return true;
          return navsById[id].minRoleNeeded;
        case 'VIEWER':
          return ['ADMIN', 'CONTRIBUTOR'].includes(navsById[id].minRoleNeeded);
        case 'CONTRIBUTOR':
          return navsById[id].minRoleNeeded === 'ADMIN';
        case 'ADMIN':
          return false;
        default:
          return navsById[id].minRoleNeeded;
      }
    });
    return privateNavIds;
  }, [isSitePrivate, navsById, userRole]);
};

function getParentNavIds({
  id,
  navsById,
}: {
  id: KnapsackNavItemId;
  navsById: Record<KnapsackNavItemId, KnapsackNavItem>;
}): KnapsackNavItemId[] {
  const parentId = navsById[id]?.parentId;

  if (!parentId || parentId === 'root') return [];

  return [parentId, ...getParentNavIds({ id: parentId, navsById })];
}

export const useParentNavIds = (id: KnapsackNavItemId): KnapsackNavItemId[] => {
  const navsById = useSelector((s) => s.navsState.byId, {
    isEqualityDeep: true,
  });
  return useMemo(() => getParentNavIds({ id, navsById }), [id, navsById]);
};

export const useHiddenTabIds = ({
  id,
  idType,
}: {
  id: string;
  idType: PageType;
}) => {
  if (idType === 'page') {
    throw new Error('Tabs not implemented on pages yet');
  }
  const pageOrPattern = useSelector((s) => s.patternsState.patterns[id], {
    isEqualityDeep: true,
  });

  return useMemo(() => {
    const hiddenTabIds = pageOrPattern.tabs?.filter((tab) => tab.hidden);
    return hiddenTabIds.flatMap((tab) => tab.id);
  }, [pageOrPattern.tabs]);
};

export const usePrivateTabIds = ({
  id,
  idType,
}: {
  id: string;
  idType: PageType;
}) => {
  if (idType === 'page') {
    throw new Error('Tabs not implemented on pages yet');
  }
  const isSitePrivate = useIsSitePrivate();
  const userRole = useUserRole();

  const pageOrPattern = useSelector((s) => s.patternsState.patterns[id], {
    isEqualityDeep: true,
  });

  return useMemo(() => {
    const privateTabIds = pageOrPattern.tabs?.filter((tab) => {
      switch (userRole) {
        case 'ANONYMOUS':
          if (isSitePrivate) return true;
          return tab.minRoleNeeded;
        case 'VIEWER':
          return ['ADMIN', 'CONTRIBUTOR'].includes(tab.minRoleNeeded);
        case 'CONTRIBUTOR':
          return tab.minRoleNeeded === 'ADMIN';
        case 'ADMIN':
          return false;
        default:
          return tab.minRoleNeeded;
      }
    });
    return privateTabIds.flatMap((tab) => tab.id);
  }, [isSitePrivate, pageOrPattern.tabs, userRole]);
};
